import React from "react";
import * as Constants from "../constants";
class WarframeFarming extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            farmSpots: [
                {
                    name: "Lith",
                    line1: "Fastest solo. Guaranteed drop. Bring a Nuke frame.",
                    line1Summary: "Hepit, Void",
                    line2: "Much slower than Hepit.",
                    line2Summary: "Taranis, Void (A rotation)",
                    imageURL: "lith.png",
                    expanded: false
                },
                {
                    name: "Meso",
                    line1: "Can almost be done AFK",
                    line1Summary: "Io, Jupiter",
                    imageURL: "meso.png",
                    expanded: false
                },
                {
                    name: "Neo",
                    line1: "Easy to do and has Neo guaranteed on A and B rotation (Also has a bonus Axi on C)",
                    line1Summary: "Mithra, Void",
                    imageURL: "neo.png",
                    expanded: false
                },
                {
                    name: "Axi",
                    line1: "~77% chance and super fast",
                    line1Summary: "Hieracon, Pluto",
                    line2: "Quick and easy, but not a guaranteed drop.",
                    line2Summary: "Apollo, Lua",
                    line3: "Most consistent, guaranteed drop on B/C rotations",
                    line3Summary: "Xini, Eros",
                    imageURL: "axi.png",
                    expanded: false
                },
                {
                    name: "Orokin Cell",
                    line1: "Basically a guaranteed drop. Can be done in 2 minutes on a good run, and extra cells can be found in the level. However, the boss can behave inconsistently and can take longer.",
                    line1Summary: "Sargas Ruk on Tethys, Saturn",
                    line2: "Not a guaranteed drop, but both have a chance of dropping in addition to the ones you find in the level, can sometimes get 2-4 in a good run. Boss is much more consistent.",
                    line2Summary: "Lech Kril and Vor",
                    line3: "Great for FarmFrames like Desecrate Nekros, Pilfer Dome Khora, or Pilfer Tentacle Hydroid. The downside is that it's not a boss node and will take longer.",
                    line3Summary: "Gabii, Ceres",
                    imageURL: 'orokincell.png',
                    expanded: false
                },
                {
                    name: "Tellurium",
                    line1: "Ophelia, Uranus: Bring FarmFrames and grind",
                    line1Summary: "Ophelia, Uranus",
                    line2: "Great map for upgrading your Archwing and a decent map to farm Tellurium",
                    line2Summary: "Salacia, Neptune",
                    imageURL: 'tellurium.png',
                    expanded: false
                }

            ]
        };
    }

    componentDidMount(){
        // fetch(Constants.apiURL)
        //     .then((value) => {
        //         //this.setState({farmSpots: value});
        //     });
    }

    handleClick(i){
        var spots = this.state.farmSpots.slice();
        console.log(spots);
        console.log(i);
        console.log(spots[i]);
        spots[i].expanded = !spots[i].expanded;
        this.setState({farmSpots: spots});
    }

    render(){
        return (
            <div className="container">
                <h1>Warframe Best Farming Locations Guide</h1>
                <div className="row">{this.state.farmSpots.map((spot, index) => {
                        return <div key={index} className={(spot.expanded ? "col-xl-8 col-md-10" : "col-md-5 col-sm-12 col-xl-4")} style={{marginBottom: 10}}><div onClick={() => {this.handleClick(index)}} className={"card "} style={{cursor: "pointer", padding: "0"}}>
                            <div className="text-center">
                                <img style={{height: 100, width: 100}} src={spot.imageURL}/>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{spot.name}</h5>
                            </div>
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-none d-md-block"><b>{spot.line1Summary}</b>{spot.expanded && (': ' + spot.line1)}</li>
                                <li className="list-group-item d-xs-block d-md-none"><b>{spot.line1Summary}</b>: {spot.line1}</li>
                                {spot.line2 && <li className="list-group-item d-none d-md-block"><b>{spot.line2Summary}</b>{spot.expanded && (': ' + spot.line2)}</li>}
                                {spot.line2 && <li className="list-group-item d-xs-block d-md-none"><b>{spot.line2Summary}</b>: {spot.line2}</li>}
                                {spot.line3 && <li className="list-group-item d-none d-md-block"><b>{spot.line3Summary}</b>{spot.expanded && (': ' + spot.line3)}</li>}
                                {spot.line3 && <li className="list-group-item d-xs-block d-md-none"><b>{spot.line3Summary}</b>: {spot.line3}</li>}
                            </ul>
                            <div className="card-body d-none d-md-block">
                                {spot.expanded ? 'Click for Less' : 'Click for More'}
                            </div>
                        </div></div>
                    })}
                </div>
            </div>
        )
    }
}

export default WarframeFarming;